<template>
  <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
    <h4>{{ $t('acc.hgen') }}</h4>
    <b-form-group :label="$t('acc.tzl')" label-for="accSettTimezone">
      <b-form-row class="align-items-center">
        <b-col>
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <Icon type="t" :isButton="false" :isNav="false" :isText="false" />
              </b-input-group-text>
            </template>
            <b-form-select id="accSettTimezone" v-model="f.timezone" :options="tList" text-field="t" value-field="v" @change="setTz" :state="tzState">
              <template #first>
                <option :value="null" disabled>- {{ $t('dial.selopt') }} -</option>
              </template>
            </b-form-select>
          </b-input-group>
        </b-col>
        <b-col v-if="!hideTzBtn" cols="auto">
          <b-button variant="primary" id="accSettTzApply" @click="onUseTz" :disabled="hideTzBtn">
            <Icon type="p" /> {{ $t('btn.p') }}
          </b-button>
        </b-col>
      </b-form-row>
      <b-form-invalid-feedback :state="tzState">
        {{ $t('acc.bw') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group :label="$t('acc.ll')" label-for="accSettLang">
      <b-form-row class="align-items-center">
        <b-col>
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <Icon type="l" :isButton="false" :isNav="false" :isText="false" />
              </b-input-group-text>
            </template>
            <LangFormDropdown id="accSettLang" v-model="f.languageId" :autoload="loadLang" />
          </b-input-group>
        </b-col>
        <b-col v-if="!hideLangBtn" cols="auto">
          <b-button variant="primary" id="accSettLangApply" @click="onUseLanguage" :disabled="hideLangBtn">
            <Icon type="p" /> {{ $t('btn.p') }}
          </b-button>
        </b-col>
      </b-form-row>
      <b-form-invalid-feedback :state="langState">
        {{ $t('acc.bw') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group :label="$t('acc.vppl')" label-for="accSettItems">
      <b-form-row class="align-items-center">
        <b-col>
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="accSettItems" v-model="f.visiblePerPage" @change="setVis" :min="1" :max="25" :debounce="200" number :state="valVis" />
          </b-input-group>
        </b-col>
        <b-col v-if="!hideVisBtn" cols="auto">
          <b-button variant="primary" id="accSettVisApply" @click="onUseVis" :disabled="hideVisBtn">
            <Icon type="p" /> {{ $t('btn.p') }}
          </b-button>
        </b-col>
      </b-form-row>
    </b-form-group>

    <template v-if="isUser">
      <h4 class="pt-3">
        {{ $t('acc.hcnt') }}
      </h4>
      <b-form-row class="align-items-center">
        <b-col cols="12" sm="6">
          <b-form-group :label="$t('acc.phl')" label-for="accSettResContact">
            <b-input-group>
              <template #prepend>
                <b-input-group-text>
                  <b-icon-phone />
                </b-input-group-text>
              </template>
              <b-input type="tel" id="accSettResContact" :state="valContact" pattern="^\+\d{1,3}[ ]*[1-9]{1}\d{1,7}[ ]*\d{3,10}$" maxlength="20" v-model="f.contactPhone" placeHolder="+49 176 123456" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group :label="$t('acc.nal')" label-for="accSettResName">
            <b-input-group>
              <template #prepend>
                <b-input-group-text>
                  <b-icon-person />
                </b-input-group-text>
              </template>
              <b-input type="text" id="accSettResName" :state="valName" maxlength="50" v-model="f.name" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-form-row>
    </template>

    <h4 class="pt-3">
      {{ $t('acc.hnot') }}
    </h4>
    <b-form-group :label="$t('acc.cel')" label-for="accSettResEmail">
      <b-form-row class="align-items-center">
        <b-col>
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-at />
              </b-input-group-text>
            </template>
            <b-input type="email" id="accSettResEmail" :state="valEmail" v-model="f.notificationEmail" maxlength="50" />
          </b-input-group>
        </b-col>
        <b-col cols="auto">
          <b-form-checkbox id="accSettConfirmEmailUseAcc" v-model="useEmail" @change="onUseEmail">
            {{ $t('acc.cec') }}
          </b-form-checkbox>
        </b-col>
      </b-form-row>
    </b-form-group>
    <!-- <b-form-group :label="$t('acc.csl')" label-for="accSettResSMS">
      <b-input-group>
        <template #prepend>
          <b-input-group-text>
            <b-icon-phone />
          </b-input-group-text>
        </template>
        <b-input type="tel" id="accSettResSMS" :state="valPhone" pattern="^\+\d{1,3}[ ]*[1-9]{1}\d{1,7}[ ]*\d{3,10}$" maxlength="20" v-model="f.notificationPhone" placeHolder="+49 176 123456" />
      </b-input-group>
    </b-form-group> -->

    <template v-if="isUser">
      <h4 class="pt-3">
        {{ $t('acc.hpay') }}
      </h4>
      <b-form-row>
        <b-col>
          <b-form-group :label="$t('acc.cpy')" label-for="accSettResPayOpt" label-cols="11" content-cols="1">
            <b-form-checkbox id="accSettResPayOpt" v-model="resetPaymentOpt" class="float-right" size="lg" />
          </b-form-group>
        </b-col>
      </b-form-row>
    </template>

    <b-form-row>
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button type="reset" variant="primary" class="mr-2 mr-sm-3" id="accSettBtnReset" :disabled="!isChanged"><Icon type="r" /> {{ $t('btn.r') }}</b-button>
        <b-button type="submit" variant="primary" id="accSettBtnSubmit" :disabled="!val || !isChanged"><Icon type="u" /> {{ $t('btn.u') }}</b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import LangFormDropdown from '@/components/shared/LangFormDropdown';
import Icon from '@/components/Icon';
import { BIconAt, BIconHash, BIconPhone, BIconPerson } from 'bootstrap-vue';
import { REST, axios } from '@/components/RestCall';
import tzList from '@/components/data/Timezones';
import { equalObjects } from '@/components/scripts/EqualObjects';

export default {
  data() {
    return {
      useEmail: false,
      resetPaymentOpt: false,
      f: {
        uuid4: '',
        timezone: null,
        languageId: null,
        visiblePerPage: 10,
        notificationEmail: null,
        notificationPhone: null,
        contactPhone: null,
        name: null,
      },
      loadLang: false,
    }
  },
  computed: {
    tList() {
      return tzList;
    },
    onlineData() {
      return this.$store.state.oUser;
    },
    valEmail() {
      if (this.f.notificationEmail === null) return null;
      let rExp = /(.+)@(.+){2,}\.(.+){2,}/;
      return rExp.test(this.f.notificationEmail.toLowerCase());
    },
    valPhone() {
      if (this.f.notificationPhone === null) return null;
      return this.f.notificationPhone.match(/^\+\d{1,3}[ ]*[1-9]{1}\d{1,7}[ ]*\d{3,10}$/gi) != null;
    },
    valContact() {
      if (!this.isUser || this.f.contactPhone == null || this.f.contactPhone === '') return null;
      return this.f.contactPhone.match(/^\+\d{1,3}[ ]*[1-9]{1}\d{1,7}[ ]*\d{3,10}$/gi) != null;
    },
    valVis() {
      if (this.f.visiblePerPage === this.onlineData.visiblePerPage) return null;
      return (this.f.visiblePerPage > 0 && this.f.visiblePerPage <= 25);
    },
    valName() {
      if (this.f.name == null || this.f.name === '') return null;
      return this.f.name.length <= 50 && this.f.name.match(/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/gi) != null;
    },
    val() {
      if (this.valVis === true || this.langState === true || this.tzState === true || this.valName === true ||
          this.valPhone === true || this.valEmail === true || this.valContact === true) {
        return (this.valEmail !== false && this.valPhone !== false && this.valContact !== false && this.valName !== false &&
                this.valVis !== false && this.f.languageId !== null);
      }
      return false;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    settLangId() {
      return this.$store.state.settings.languageId;
    },
    settTz() {
      return this.$store.state.settings.timezone;
    },
    settVis() {
      return this.$store.state.settings.visiblePerPage;
    },
    hideLangBtn() {
      if (this.f.languageId == null || this.loadLang == true) return true;
      return this.f.languageId == this.settLangId;
    },
    langState() {
      if (this.f.languageId === this.settLangId) {
        return (this.f.languageId !== this.$store.state.oUser.languageId) ? true : null;
      }
      return false;
    },
    hideTzBtn() {
      if (this.f.timezone == null) return true;
      return this.f.timezone == this.settTz;
    },
    tzState() {
      if (this.f.timezone === this.settTz) {
        return (this.f.timezone !== this.$store.state.oUser.timezone) ? true : null;
      }
      return false;
    },
    hideVisBtn() {
      if (this.f.visiblePerPage == null) return true;
      return this.f.visiblePerPage == this.settVis;
    },
    isChanged() {
      return !equalObjects(this.$store.state.oUser, this.f) || this.resetPaymentOpt === true;
    },
  },
  watch: {
    onlineData: function(data) {
      this.f = {...data};
    }
  },
  created() {
    // maybe instead of getting again, share with AccountLogin (via timestamp)
    REST.get('/' + (this.isUser ? 'users' : 'customers') + '/' + this.$store.getters.uuid)
        .then(resp => {
          this.$store.dispatch('setUser', resp.d.data);
        }).catch(e => {
          this.$store.commit('showWarn',e.message)
        })
  },
  methods: {
    onSubmit() {
      let data = {...this.f};
      if (this.isUser) {
        if (data['name'] === '') {
          data['name'] = null;
        }
        if (data['contactPhone'] === '') {
          data['contactPhone'] = null;
        }
      } else {
        delete data['name'];
        delete data['contactPhone'];
        delete data['currency'];
      }
      delete data['emailAddress'];
      axios.all([
            ... (this.resetPaymentOpt === true) ? [REST.delete('/users/' + this.$store.getters.uuid + '/paymentdetails')] : [],
            ... !equalObjects(this.$store.state.oUser, this.f) ? [REST.put('/' + (this.isUser ? 'users' : 'customers') + '/' + this.$store.getters.uuid + '/info', data)] : [],
          ]).then(axios.spread((resp1) => {
            this.$store.commit('showSuccess',resp1.d.message); // just swallow resp2
            this.$store.commit('setUser', this.f);
            console.log(resp1)
          })).catch(e => {
            this.$store.commit('showWarn',e.message); // one error, and we're here
          }).finally(() => {
            this.onReset();
          });
    },
    onReset() {
      this.f = {...this.$store.state.oUser}; // everything resets, except language - which is synced via store already
      this.useEmail = false;
      this.resetPaymentOpt = false;
    },
    onUseEmail(checked) {
      if (checked) {
        this.f.notificationEmail = this.$store.state.oUser.emailAddress;
      } else {
        this.f.notificationEmail = null
      }
    },
    onUseLanguage() {
      this.loadLang = true;
      this.$nextTick(() => { this.loadLang = false; });
    },
    onUseTz() {
      this.setTz(this.f.timezone);
    },
    setTz(tz) {
      if (this.settTz !== tz) {
        this.$store.commit('setTimezone', tz);
      }
    },
    onUseVis() {
      this.setVis(this.f.visiblePerPage);
    },
    setVis(num) {
      if (this.settVis !== num) {
        this.$store.commit('setVisiblePerPage', num);
      }
    },
  },
  components: {
    LangFormDropdown,
    Icon,
    BIconAt,
    BIconHash,
    BIconPhone,
    BIconPerson,
  },
}
</script>
